export const setNavbarState = value => ({
    type: "SET_STATE",
    payload: value
});

export const setNavbarCity = value => ({
    type: "SET_CITY",
    payload: value
});

export const setNavbarWard = value => ({
    type: "SET_WARD",
    payload: value
});

export const removeNavbarState = () => ({
    type: "REMOVE_STATE",
});

export const removeNavbarCity = () => ({
    type: "REMOVE_CITY",
});

export const removeNavbarWard = () => ({
    type: "REMOVE_WARD",
});

export const removeNavbarAll= () => ({
    type: "REMOVE_ALL",
});

