import axios from 'axios';
// import * as India from "../../India.geojson";
// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('email');
    if (userStr) return JSON.parse(userStr);
    else return null;
};

export const getUserName = () => {
    const userName = sessionStorage.getItem('name');
    if (userName) return userName;
    else return 'User';
};

// return the token from the session storage
export const getToken = () => {
    if (sessionStorage.getItem('token')) {
        var started = sessionStorage.getItem('started');
        var diff = Date.now() - started;

        if (diff >= 60000 * 29 ) {
                var refreshToken = sessionStorage.getItem("refreshToken");
                axios.post(
             `${process.env.REACT_APP_BACKEND_URL}/api/token/refresh/`,
                  {
                    refresh: refreshToken,
                  },
                )
                  .then((response) => {
                    //console.log(response.data.access);
                    sessionStorage.setItem("token", response.data.access);
                    sessionStorage.setItem("started", Date.now());
                  })
                  .catch((error) => {
                    console.log("Something went wrong. Please try again later.");
                  });
            return sessionStorage.getItem('token') || null;
        } else {
            return sessionStorage.getItem('token') || null;
        }
    } else {
        return;
    }
};

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('started');
    sessionStorage.removeItem('name');
};

// set the token and user from the session storage
export const setUserSession = (token, email, user) => {
    sessionStorage.setItem('token', token.access);
    sessionStorage.setItem('refreshToken', token.refresh);
    sessionStorage.setItem('started', Date.now());
    sessionStorage.setItem('email', JSON.stringify(email));
    sessionStorage.setItem('name', user.name ? user.name : 'User');
};

export const checkGeoJson = async (selectedState) => {
    try {
        // if (selectedState) {
        const res = await axios.post(
            `${process.env.REACT_APP_GIS_URL}/geojson`,
            {
                level: 2,
                thislevel: selectedState,
            }
        );
        // console.log(res.data);
        return res.data;
        // } else return India;
    } catch (err) {
        if (err.response.status === 404) {
            return Promise.reject(err);
        }
    }
};
