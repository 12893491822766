import React, { Suspense } from "react";
import ReactGA from "react-ga";

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  //useHistory,
  //useLocation,
} from "react-router-dom";
import PrivateRoute from "./shared/utils/PrivateRoute";
import Navbar from "./shared/components/Navbar/Navbar";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner/LoadingSpinner";
import "./App.css";

const Login = React.lazy(() => import("./Pages/Login/Login"));
// const NewUser = React.lazy(() => import("./Pages/NewUser/NewUser"));
const Shramik = React.lazy(() => import("./Pages/Shramik/Shramik.jsx"));
const Register = React.lazy(() => import("./Pages/Register/Register"));
const Forgot = React.lazy(() => import("./Pages/Forgot/Forgot"));
const Profile = React.lazy(() => import("./Pages/Profile/Profile"));
const JobPostings = React.lazy(() => import("./Pages/JobPostings/JobPostings"));
//const NextRegister = React.lazy(() => import("./Pages/Register/NextRegister"));

const UpdateProfile = React.lazy(() =>
  import("./Pages/UpdateProfile/UpdateProfile")
);

//const Industry = React.lazy(() => import("./Pages/Industry/Industry.jsx"));
// const SkillMatch = React.lazy(() =>
//   import("./Pages/SkillMatch/Skillmatch.jsx")
// );
// const SkillGap = React.lazy(() => import("./Pages/SkillGap/Skillgap.jsx"));
function App() {
  // ReactGA.initialize(process.env.REACT_APP_GA_ID, []);
  //const history = useHistory();

  return (
    <div className="App">
      <BrowserRouter>
        {/* {history.location.pathname === "/surveyform" ? null : */}
        <Navbar />
        {/* } */}
        <div className="content">
          <Suspense
            fallback={
              <div>
                <LoadingSpinner asOverlay />
              </div>
            }
          >
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute path="/shramik" component={Shramik} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/jobpostings" component={JobPostings} />

              <Route path="/updateprofile" component={UpdateProfile} />
              {/* <Route path="/industry" component={Industry} />
              <Route path="/skillmatch" component={SkillMatch} />
              <Route path="/skillgap" component={SkillGap} />*/}
              <Route path="/register" component={Register} />
              <Route path="/forgot" component={Forgot} />
              {/* <Route path="/nextregister" component={NextRegister} /> */}
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
