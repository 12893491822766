import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import MiddleLayerSelection from "./MiddleLayer/middlelayer.reducer";
import NavbarSelection from "./Navbar-Selection/navbarSelection.reducer";
import GlobalCities from "./Cities/cities.reducer";
import UserProfile from "./User-Profile/userProfile.reducer";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["userProfile"],
};

const rootReducer = combineReducers({
  navbarSelection: NavbarSelection,
  cities: GlobalCities,
  option: MiddleLayerSelection,
  userProfile: UserProfile,
});

export default persistReducer(persistConfig, rootReducer);
