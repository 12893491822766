const INITIAL_STATE = {
    cities: null
};

const GlobalCities = (state = INITIAL_STATE, action) => {

    switch(action.type) {

        case "SET_CITIES": return {
            ...state,
            cities: action.payload
        }

        case "REMOVE_CITIES": return {
            ...state,
            cities: null
        }

        default: return state;
    }
};

export default GlobalCities;