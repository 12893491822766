import React, { useMemo, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector, connect } from "react-redux";
import { InputLabel, MenuItem, Menu } from "@material-ui/core";
import AccountCircleRounded from "@material-ui/icons/AccountCircleRounded";
import { useHistory, useLocation } from "react-router-dom";
import { getToken, getUserName } from "../../utils/Common";
// import logo from "../../../assets/img/Vector.svg";
import { removeUserSession } from "../../utils/Common";
import {
  setNavbarState,
  setNavbarCity,
  setNavbarWard,
  removeNavbarAll,
  removeNavbarCity,
  removeNavbarWard,
} from "../../../redux/Navbar-Selection/navbarSelection.actions";
import { removeCities, setCities } from "../../../redux/Cities/cities.actions";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import "./Navbar.css";
import useGaTracker from "../../utils/useGaTracker";
import ReactGA from 'react-ga';

//import { EXISTING_SAKSHAM_BASE_URL } from "../../../API";
// import SurveyForm from "../SurveyForm/SurveyForm.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "120px",
    maxHeight: "120px",
  },
  appBar: {
    backgroundColor: "#20314d",
    height: "90%",
    minHeight: "64px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const customStyles = {
  control: (base, state) => ({
    ...base,
    fontWeight: "bold",
    background: "whitesmoke",
    borderColor: "whitesmoke",
    borderRadius: "20px 20px 20px 20px",
    boxShadow: state.isFocused ? null : null,
    width: state.selectProps.width,
    pointerEvents: "auto",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
  }),
  menu: (base) => ({
    ...base,
    //borderRadius: 0,
    hyphens: "auto",
    marginTop: 0,
    textAlign: "left",
    color: "#20314d",
    borderRadius: "20px 20px 20px 20px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: "bold",
    fontSize: "16px",
    color: "#20314d",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    fontWeight: "bold",
    fontSize: "16px",
    color: "#20314d",
  }),
  menuList: (base) => ({
    ...base,
    background: "whitesmoke",
    padding: 0,
    borderRadius: "20px",
    //whiteSpace:
  }),
  option: (provided) => ({
    ...provided,
    //whiteSpace: "noWrap",
    borderRadius: "20px",
    fontSize: "16px",
  }),
};

const Navbar = (props) => {
  useGaTracker();

  const [anchorEl, setAnchorEl] = useState(null);
  const [cityData, setCityData] = useState(null);
  // const [link, setLink] = useState(null);
  const SurveyLink = "https://saksham.industryimpactstudy.org/";
  const classes = useStyles();
  const token = getToken();

  const { setNavbarState, setNavbarCity } = props;

  const { selectedState, selectedCity } = useSelector(
    (state) => state.navbarSelection
  );

  const history = useHistory();
  let location = useLocation();
  const [states, setStates] = useState(null);
  let checkPath =
    history.location.pathname === "/surveyform" ||
    history.location.pathname === "/updateprofile";
  // console.log(selectedCity, selectedState);
  // console.log(location);

  // useEffect(() => {
  //   // if (selectedState === "Uttar Pradesh") selectedCity = "Agra";
  //   // if (selectedState === "Maharashtra") selectedCity = "Akola";
  //   // console.log('setting...')
  //   setNavbarState("Uttar Pradesh");
  //   setNavbarCity("Agra");
  // }, [setNavbarState, setNavbarCity]);

  // useEffect(() => {
  //   selectedState === "Uttar Pradesh"
  //     ? setNavbarCity("Agra")
  //     : setNavbarCity("Akola");
  // }, [selectedState, setNavbarCity]);

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/State`,
          {
            level: 1,
            thislevel: "India",
            locale: "en",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setStates(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    token && fetchStateList();
  }, [token]);
  // if (states != null) {
  //   var StateList = states.sort().map(function (item) {
  //     return { label: item, value: item };
  //   });
  // }
  //console.log(StateList);

  useEffect(() => {
    const fetchCity = () => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/District`,
          {
            level: 2,
            thislevel: selectedState,
            locale: "en",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setCityData(res.data);
          setNavbarCity(res.data[0].value);
          //console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    token && selectedState && fetchCity();
  }, [selectedState, token, setNavbarCity]);

  //put this in useffect
  // if (cityData != null) {
  //   var City = cityData.map(function (item) {
  //     return { label: item, value: item };
  //   });
  // }

  const handleLogout = () => {
      ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: 'Logout',
      });
  
    setAnchorEl(null);
    removeUserSession();
    props.removeNavbarAll();
    history.push("/");
    props.removeCities();
  };

  let path =
    history.location.pathname === "/" ||
    history.location.pathname === "/register" ||
    history.location.pathname === "/forgot";

  const handleCityChange = useCallback(
    (data) => {
      data ? setNavbarCity(data.value) : setNavbarCity("");
    },
    [setNavbarCity]
  );

  // const handleStateChange = useCallback(
  //   (value) => {
  //     value ? setNavbarState(value.value) : setNavbarState("");
  //     setNavbarCity("");
  //   },
  //   [setNavbarState, setNavbarCity]
  // );
  const handleStateChange = useCallback(
    (data) => {
      //console.log(event);
      data ? setNavbarState(data.value) : setNavbarState("");
      // console.log('switch')
      // switch (value.value) {
      //   case "Uttar Pradesh":
      //     setNavbarState("Uttar Pradesh");
      //     // set city list
      //     setNavbarCity("Agra");
      //     break;
      //   case "Maharashtra":
      //     setNavbarState("Maharashtra");
      //     //set city list
      //     setNavbarCity("Ahmednagar");
      //     break;
      //   case "Bihar":
      //     setNavbarState("Bihar");
      //     //set city list
      //     setNavbarCity("Araria");
      //     break;
      //   case "Delhi":
      //     setNavbarState("Delhi");
      //     //set city list
      //     setNavbarCity("Central");
      //     break;
      //   case "Karnataka":
      //     setNavbarState("Karnataka");
      //     //set city list
      //     setNavbarCity("Bagalkote");
      //     break;
      //   case "Rajasthan":
      //     setNavbarState("Rajasthan");
      //     //set city list
      //     setNavbarCity("Ajmer");
      //     break;
      //   case "Telangana":
      //     setNavbarState("Telangana");
      //     //set city list
      //     setNavbarCity("Adilabad");
      //     break;

      //   default:
      //     // setNavbarState(UTTARPRADESH);
      //     // setNavbarCity("Agra");
      //     break;
      // }
      //console.log(value);
    },
    [setNavbarState]
  );

  const inputLabelStyle = useMemo(
    () => ({
      color: "white",
      marginTop: "10px",
      marginBottom: "2px",
      textAlign: "center",
      paddingRight: "20px",
      fontSize: "18px",
    }),
    []
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return !path ? (
    !checkPath ? (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar className={classes.appBar}>
            <div
              className={`logo-saksham`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                // height: "100%"
              }}
              onClick={() => {
                history.push("/shramik");
              }}>
              {/* <img
                src={logo}
                alt="Sapio Analytics"
                style={{
                  height: "100px",
                  width: "140px",
                  cursor: "pointer",
                }}
                
              /> */}
            </div>
            <div className="navbar-items">
              <div className="navbar__selection">
                <div className="navbar-input-box">
                  <InputLabel id="select-state-label" style={inputLabelStyle}>
                    State
                  </InputLabel>
                  <Select
                    name="select-state"
                    options={states}
                    styles={customStyles}
                    value={
                      selectedState === ""
                        ? null
                        : { label: selectedState, value: selectedState }
                    }
                    width="250px"
                    onChange={handleStateChange}
                    isDisabled={
                      history.location.pathname === "/shramik" ? false : true
                    }
                  />
                </div>
              </div>

              <div className="navbar__selection">
                <div className="navbar-input-box">
                  <InputLabel id="select-state-label" style={inputLabelStyle}>
                    City
                  </InputLabel>
                  <Select
                    name="select-state"
                    placeholder="Please Select"
                    value={
                      selectedCity === ""
                        ? null
                        : { value: selectedCity, label: selectedCity }
                    }
                    options={cityData}
                    onChange={handleCityChange}
                    styles={customStyles}
                    width="250px"
                    isDisabled={
                      history.location.pathname === "/shramik" ? false : true
                    }
                  />
                </div>
              </div>
              {/* {link && ( */}
              <div
                className="navbar__selection"
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  right: 0,
                  alignItems: "start",
                }}>
                {/* <Button
                  variant="outlined"
                  href={SurveyLink}
                  style={{
                    color: "#20314d",
                    width: "100px",
                    backgroundColor: "whiteSmoke",
                    fontSize: "0.6em",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    whiteSpace: "wrap",
                    padding: "0.5em",
                    textAlign: "center",
                  }}
                  // onClick={() => history.push("/updateprofile")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Complete Your Profile
                </Button> */}
                <div className="user_profile">
                  <AccountCircleRounded
                    className="user_logo"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={{
                      height: "32px",
                      // fontSize: "2em"
                    }}></AccountCircleRounded>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ top: "2.5vh" }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    getContentAnchorEl={null}>
                    {/* <MenuItem
                  onClick={() => {
                  handleClose();
                  history.push("/shramik");
                  }}
                  style={{ boxShadow: "none" }}
                >
                  Home
                </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        history.push("/profile");
                        setAnchorEl(null);
                      }}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                  <span className="username">{getUserName()}</span>
                </div>
              </div>
              {/* )} */}
            </div>
          </Toolbar>

          <div className="navbar-label">
            A project by TIFAC (an autonomous body of Department of Science &
            Technology, Govt. of India) in collaboration with M/s. Sapio
            Analytica Pvt. Ltd., Mumbai
          </div>
        </AppBar>

        {/* <main className={classes.content}></main> */}
      </div>
    ) : null
  ) : null;
};

const mapDispatchToProps = (dispatch) => ({
  setNavbarState: (value) => dispatch(setNavbarState(value)),
  setNavbarCity: (value) => dispatch(setNavbarCity(value)),
  setNavbarWard: (value) => dispatch(setNavbarWard(value)),
  removeNavbarAll: () => dispatch(removeNavbarAll()),
  setCities: (value) => dispatch(setCities(value)),
  removeCities: () => dispatch(removeCities()),
  removeNavbarCity: () => dispatch(removeNavbarCity()),
  removeNavbarWard: () => dispatch(removeNavbarWard()),
});

export default React.memo(connect(null, mapDispatchToProps)(Navbar));
